const weddingProductCategory = [
    {
        id: 1,
        src: "../Wedding/Invite/73.png",
        alt: "Image 1",
        title: "invitation",
        url: "/wedding/invitation",
    },
    {
        id: 2,
        src: "../Wedding/Menu/60.png",
        alt: "Image 2 ",
        title: "menu card",
        url: "/wedding/menu-card",
    },
    {
        id: 3,
        src: "../Wedding/PlaceCard/77.png",
        alt: "Image 3",
        title: "place card",
        url: "/wedding/place-cards",
    },
    {
        id: 4,
        src: "../Wedding/Program/36.png",
        alt: "Image 4",
        title: "programme",
        url: "/wedding/programme",
    },
    {
        id: 5,
        src: "../Wedding/Souvenir/52.png",
        alt: "Image 5",
        title: "souvenir & favours",
        url: "/wedding/souvenir-&-favours"
    },
    {
        id: 5,
        src: "../Wedding/PlaceCard/48.png",
        alt: "Image 5",
        title: "table card",
        url: "/wedding/table-cards"
    },
    {
        id: 6,
        src: "../Wedding/Shirt/11.png",
        alt: "Image 5",
        title: "shirt",
        url: "/wedding/couple-shirt"
    },
    {
        id: 7,
        src: "../Wedding/Box/17.png",
        alt: "Image 5",
        title: "box",
        url: "/wedding/boxes"
    },
    {
        id: 8,
        src: "../Wedding/Envelope/11.png",
        alt: "Image 5",
        title: "envelope",
        url: "/wedding/envelope"
    },
   
    


];

export default weddingProductCategory;