import axios from 'axios';

export const fetchConversionRates = async () => {
    try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        const data = response.data;
        
        if (!data.rates.JMD) {
            throw new Error('JMD rate not found in API response');
        }

        return {
            USD_JMD: data.rates.JMD,
            JMD_USD: 1 / data.rates.JMD,
        };
    } catch (error) {
        console.error('Error fetching conversion rates:', error);
        return {
            USD_JMD: 155, // Default rate
            JMD_USD: 1 / 155, // Default rate
        };
    }
};
