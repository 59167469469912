import React from 'react';
import HeaderSection from './HeaderSection';
import FeaturedCategoriesCarousel from './FeaturedCategoriesCarousel';
const FeaturedCategories = () => {
    return (
        <div>
         <HeaderSection 
            title='Product Category' 
            body='We cater to both wedding and corporate industry.'
            />
            <FeaturedCategoriesCarousel/>
        </div>
    );
};

export default FeaturedCategories;