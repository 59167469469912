import React from 'react';
import aboutUs from '../../assets/images/aboutUs4.png';
import styles from './ConnectWithUs.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faTiktok, faFacebook} from '@fortawesome/free-brands-svg-icons';


const ConnectWithUs = () => {
    const phoneNumber = 18762382419;

    return (
        <div className={styles.heroContainer}>
            <div className={styles.textContainer}>
                <h1 className={styles.headerText}>Connect with Us</h1>
                <ul>
                    <li className={styles.subText}>
                        <span><FontAwesomeIcon icon={faTiktok}/> <b>TikTok: </b></span>
                        <span>pci_neikadesignz</span>
                    </li>
                    <li className={styles.subText}>
                        <span><FontAwesomeIcon icon={faFacebook}/> <b>Facebook: </b> </span>
                        <span>pci_neikadesignz</span>
                     </li>
                    <li className={styles.subText}>
                        <span><FontAwesomeIcon icon={faInstagram}/> <b>Instagram: </b> </span>
                        <span>pci_neikadesignz</span>
                    </li>
                </ul>
                <p className={styles.subText}>We're excited to be part of your special moments. Whether you're planning a wedding, a corporate event, or simply want to add a creative touch to your personal projects, PC&I is here to bring your ideas to life.</p>
                <p className={styles.subText}>Feel free to explore our portfolio, browse our catalog, or get in touch with us to discuss your vision. We can't wait to be part of your next memorable event!</p>
                <p className={styles.subText}><a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noreferrer"> Contact Us </a></p>
            </div>
            <div className={styles.imageContainer}>
                <img src={aboutUs} alt="Hero" className={styles.heroImage} loading="lazy" />
            </div>
        </div>
    );
};

export default ConnectWithUs;