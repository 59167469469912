import React from 'react';
import HeaderSection from './HeaderSection';
import FeaturedProductsCarousel from './FeaturedProductsCarousel';

const FeaturedProducts = () => {
    return (
        <div>
            <HeaderSection title='Best Sellers' body='Take a look at our bestselling products.' />
            <FeaturedProductsCarousel/>
        </div>
    );
};

export default FeaturedProducts;