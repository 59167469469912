import React from 'react';
import weddingProductCategory from '../../data/wedding/weddingProductCategory';
import HeaderSection from '../../components/HeaderSection';
import ProductCategoryCarousel from '../../components/ProductCategoryCarousel';
import { Helmet } from 'react-helmet';
const Wedding = () => {
    return (
        <div>
              <Helmet>
            <title>Elegant Wedding Invitations - Custom Designs by Personalized Cards and Invitations</title>
            <meta name="description" content="Explore our elegant and custom wedding invitations, designed to make your special day even more memorable." />
        </Helmet>
            <HeaderSection title='wedding Category' body='Explore our collection of beautiful wedding stationery.'/>
            <ProductCategoryCarousel data={weddingProductCategory}/>
        </div>
    );
};

export default Wedding;