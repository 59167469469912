import React, { useState } from 'react';
import styles from '../FloatingCurrencyToggle/FloatingCurrencyToggle.module.css'; // Import the styles for the button

const FloatingCurrencyToggle = ({ currency, onCurrencyChange }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleCurrencySelect = (selectedCurrency) => {
        onCurrencyChange(selectedCurrency);
        setIsMenuOpen(false);
    };

    return (
        <div className={styles.floatingButton}>
            <button 
                className={styles.button} 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Toggle currency"
            >
                {currency}
            </button>
            {isMenuOpen && (
                <div className={styles.menu}>
                    <button onClick={() => handleCurrencySelect('USD')}>USD</button>
                    <button onClick={() => handleCurrencySelect('JMD')}>JMD</button>
                </div>
            )}
        </div>
    );
};

export default FloatingCurrencyToggle;
