const corporateProductCategory = [
    {
        id: 1,
        src: "../Corporate/Banner/5.png",
        alt: "Image 5",
        title: "banner",
        url: "/corporate/banner"
    },{
        id: 2,
        src: "../Corporate/Business/11.png",
        alt: "Image 2 ",
        title: "business card",
        url: "/corporate/business-card",
    },
    {
        id: 3,
        src: "../Corporate/Calendar/16.png",
        alt: "calendar",
        title: "calendar",
        url: "/corporate/calendar"
    },
    {
        id: 4,
        src: "../Corporate/Sticker/13.png",
        alt: "sticker",
        title: "sticker",
        url: "/corporate/sticker"
    }, 
    {
        id: 5,
        src: "../Corporate/Shirt/2.png",
        alt: "promotional items",
        title: "promotional items",
        url: "/corporate/promotional-items",
    },
    {
        id: 6,
        src: "../Corporate/Letterhead/17.png",
        alt: "letter head",
        title: "letter head",
        url: "/corporate/letter-head"
    },
    {
        id: 7,
        src: "../Corporate/ThankYouCard/4.png",
        alt: "promotional cards",
        title: "promotional cards",
        url: "/corporate/promotional-cards"
    },
    {
        id: 8,
        src: "../Other/logo.png",
        alt: "logo",
        title: "logo",
        url: "/corporate/logo"
    },
    {
        id: 9,
        src: "../Corporate/Menu/20.png",
        alt: "Menu",
        title: "menu",
        url: "/corporate/menu"
    },
];

export default corporateProductCategory;