import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './TermsAndConditions.module.css';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
    return (
        <div>
            <Helmet>
            <title> Terms and Conditions - Personalized Cards and Invitations</title>
            <meta name="description" content="Review the Terms and Conditions at Personalized Cards and Invitations. This page outlines the rules, policies, and legal agreements governing the use of our website and services for custom cards and invitations." />
    </Helmet>
            <HeaderSection title='Terms & Conditions'/>
            <div className={styles.termsContainer}>
                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>1. Introduction</h2>
                    <p>
                    Welcome to PC&I, where our commitment is to deliver superior products and an unparalleled
customer journey. By engaging with our website and services, you acknowledge and accept the
terms and conditions outlined herein.     </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>2. Product Information</h2>
                    <p>
                    PC&I specializes in an extensive array of stationery items, encompassing invitations, menu cards,
programs, and beyond. While we diligently strive for precision in our product descriptions and
imagery, please be advised that minor color differentiations may arise due to variations between
print and digital representations. Embracing the distinctive essence of our custom graphic designs
and artisanal craftsmanship is integral to our offerings.
      </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>3. Ordering and Payment</h2>
                    <p>
                    To proceed with an order through PC&I:  </p>
                    <ol>
                        <li><strong>Select and submit your order.</strong></li>
                        <li><strong>Receive quotation</strong></li>
                        <ul>
                            <li>PC&I representative will get in touch with you and provide invoice for payment.</li>
                        </ul>
                        <li><strong>Make Payment Deposit.</strong></li>
                        <ul>
                            <li>You have the option to pay in full or make 50% deposit of the total amount to initiate the design process.</li>
                        </ul>
                        <li><strong>Design Draft.</strong></li>
                        <ul>
                            <li>Our team will work on creating a design draft based on your requirements within  3-7 business days.</li>
                            <li>You will have the opportunity to review and confirm the design.</li>
                        </ul>
                        <li><strong>Balance Payment.</strong></li>
                        <ul>
                            <li>Upon confirmation of the design draft, the remaining 50% of the total amount is to be paid to confirm production.</li>
                        </ul>
                        <li><strong>Production and Delivery.</strong></li>
                        <ul>
                            <li>Kindly allow a timeframe of 3 to 6 weeks for production completion. However, based on the quantity
ordered you may receive it sooner.
Following production, delivery will be facilitated through Bearer Services, Knutsford Courier
Services, or Tara Courier Service.
</li>
                        </ul>
                    </ol>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>4. Shipping and Delivery</h2>
                    <p>
                    For your convenience, we extend islandwide shipping services. Delivery timelines may vary based
on your location and the specific product selected. Estimated delivery schedules will be provided
during the ordering process. While we endeavor to adhere to these estimates, please understand
that delays may occasionally arise due to unforeseen circumstances. Your patience and
understanding in such instances are greatly appreciated. Delivery options include Bearer Services,
Knutsford Courier Services, or Tara Courier Service, ensuring reliable and efficient delivery choices.   </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>5. Cancellation Policy</h2>
                    <p>
                    Once production has commenced, customers opting to cancel their orders will be responsible for
covering any utilized materials and time, as well as the full graphic design fee. Cancellations
initiated post-design confirmation or during the design process will incur the design fee. </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.termsHeader}>6. Returns and Corrections</h2>
                    <ul>
                      <li>Returns are only accepted if there was an error made on our part.</li>
                      <li>Returns must be requested within 3 days of ordering.</li>
                      <li>No refunds will be issued, but we will promptly make corrections to rectify any errors on our part.</li>
                      <li>Customer will bear the shipping cost of the returns.</li>
                    </ul>
                </section>
                <section className={styles.section}>
                    <p>By engaging with our services, you acknowledge that you have thoroughly read, comprehended,
and consented to these terms and conditions. PC&I retains the right to amend or update these
terms as deemed necessary, and we encourage you to periodically review them.
Thank you for entrusting PC&I with your stationery requirements. We eagerly anticipate the
opportunity to serve you and contribute to the enhancement of your special occasions.</p>

                      <p>  Thank you for choosing PC&I for your stationery needs. We look forward to serving you and making your special moments even more memorable.  </p>
                </section>
            </div>

        </div>
    );
};

export default TermsAndConditions;