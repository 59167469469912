import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './RefundPolicy.module.css';
import { Helmet } from 'react-helmet';

const RefundPolicy = () => {
    return (
        <div>
              <Helmet>
            <title> Refund Policy - Personalized Cards and Invitations</title>
            <meta name="description" content="Read our Refund Policy at Personalized Cards and Invitations to understand the terms and conditions for refunds on our custom cards and invitations. Learn about eligibility, procedures, and how we handle refunds to ensure customer satisfaction." />
  </Helmet>
            <HeaderSection title='Refund Policy'/>
            <div className={styles.policyContainer}>
                <section className={styles.section}>
                    <h2 className={styles.policyHeader}>1. Refund Eligibility</h2>
                    <p>
                        We understand that situations may arise where you may need to request a refund. To be eligible for a refund, the following conditions must be met:
                    </p>
                    <ul>
                        <li><strong>Order Error:</strong> You have received an incorrect order that was our mistake.</li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.policyHeader}>2. Refund Process</h2>
                    <p>
                        If you meet the eligibility criteria mentioned above, please follow these steps to initiate the refund process:
                    </p>
                    <ol>
                        <li><strong>Contact Us:</strong> Please contact us within 3 days of receiving your order to report the issue and request a refund.</li>
                        <li><strong>Provide Information:</strong> To process your refund, we may ask for the following information:
                            <ul>
                                <li>Order / Receipt number.</li>
                                <li>Description and clear photos of the issue or damage.</li>
                            </ul>
                        </li>
                        <li><strong>Review and Approval:</strong> Our team will review your request and assess whether it meets our refund criteria.</li>
                        <li><strong>Refund or Replacement:</strong> Depending on the nature of the issue and your preference, we will either issue a refund for the item or arrange for a replacement.</li>
                        <li><strong>Note:</strong> Customer is liable to receive the balance left after the design fee and other applicable fees have been deducted.</li>
                    </ol>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.policyHeader}>3. Non-Refundable Items</h2>
                    <p>
                        Please note that the following items are non-refundable:
                    </p>
                    <ul>
                        <li><strong>Customized Products:</strong> Items that have been customized or personalized according to your specifications are non-refundable unless they contain errors on our part.</li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.policyHeader}>4. Contact Us</h2>
                    <p>
                        If you have any questions or concerns about our refund policy or need assistance with a refund request, please feel free to contact us.
                    </p>
                </section>

                <p>
                    Thank you for choosing PC&I for your stationery needs. We strive to provide quality products and ensure your satisfaction with every order.
                </p>
            </div>
        </div>
    );
};

export default RefundPolicy;