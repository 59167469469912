import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCategoryCard from './ProductCategoryCard';
import styles from './ProductCarousel.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ProductCategoryCarousel = ({ data }) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const CustomPrevArrow = (props) => (
        <button {...props} className={styles['carousel-arrow-left']}>
            <span><FontAwesomeIcon icon={faChevronLeft} /></span>
        </button>
    );

    const CustomNextArrow = (props) => (
        <button {...props} className={styles['carousel-arrow-right']}>
            <FontAwesomeIcon icon={faChevronRight} />
        </button>
    );

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4, // Number of cards to show at once
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className={styles['product-carousel-container']}>
            {isDesktop ? (
                <Slider {...settings}>
                    {data.map((product, index) => (
                        <div key={index} className={styles['product-card-container']}>
                            <ProductCategoryCard product={product} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className={styles['product-list-container']}>
                    {data.map((product, index) => (
                        <div key={index} className={styles['product-card-container']}>
                            <ProductCategoryCard product={product} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ProductCategoryCarousel;
