import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.css';
import logo from '../assets/logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faTiktok} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const phoneNumber = 18762382419;
    return (

        <Fragment>
            <section className={styles.footerBg}>
                <div className={styles.footer}>
                    <div className={styles.footerContent}>
                        {/* Business Information */}
                        <div className={styles.footerBrand}>
                            <img src={logo} alt="PC&I Logo" className={styles.footerLogo} />
                            <p className={styles.footerContact}>Handing value to your loved ones</p>
                            <p className={styles.footerContact}>Contact: (876) 238-2419</p>
                            <p className={styles.footerContact}>Location: Kingston, Ja</p>
                        </div>

                        {/* Wedding Section */}
                        <div className={styles.footerSection}>
                            <h3 className={styles.footerSectionTitle}>Wedding</h3>
                            <NavLink to='/wedding/box' className={styles.footerNavItems}>
                                Cake Boxes
                            </NavLink>
                            <NavLink to='/wedding/invitation' className={styles.footerNavItems}>
                                Invitations
                            </NavLink>
                            <NavLink to='/wedding/programme' className={styles.footerNavItems}>
                                Programs
                            </NavLink>
                            <NavLink to='/wedding/shirt' className={styles.footerNavItems}>
                                Shirts
                            </NavLink>
                            <NavLink to='/wedding/menu%20card' className={styles.footerNavItems}>
                                Menu Card
                            </NavLink>
                            <NavLink to='/wedding/souvenir%20&%20favours' className={styles.footerNavItems}>
                                Souvenir
                            </NavLink>
                        </div>

                        {/* Policies Section */}
                        <div className={styles.footerSection}>
                            <h3 className={styles.footerSectionTitle}>Policies</h3>
                            <NavLink to='/terms-and-conditions' className={styles.footerNavItems}>
                                Terms and Conditions
                            </NavLink>
                            <NavLink to='/shipping-policy' className={styles.footerNavItems}>
                                Shipping Policy
                            </NavLink>
                            <NavLink to='/refund-policy' className={styles.footerNavItems}>
                                Refund Policy
                            </NavLink>
                        </div>

                        {/* About Us Section */}
                        <div className={styles.footerSection}>
                            <h3 className={styles.footerSectionTitle}>About Us</h3>
                            <NavLink to='/about-us' className={styles.footerNavItems}>
                                About PC&I
                            </NavLink>
                            <NavLink to='/reviews' className={styles.footerNavItems}>
                                Reviews
                            </NavLink>
                            <NavLink to={`https://wa.me/${phoneNumber}`}  target='_blank' className={styles.footerNavItems}>
                                Contact Us
                            </NavLink>
                        </div>

                        {/* Resources Section */}
                        <div className={styles.footerSection}>
                            <h3 className={styles.footerSectionTitle}>Resources</h3>
                            <NavLink to='/neikas-wedding-corner' className={styles.footerNavItems}>
                                Blog - Neika's Wedding Corner
                            </NavLink>
                            <NavLink to='/faqs' className={styles.footerNavItems}>
                                FAQs
                            </NavLink>
                            <NavLink to='/forms' className={styles.footerNavItems}>
                            Form Links
                            </NavLink>
                        </div>
                    </div>
                    <div className={styles.footerIconBg}>
                        {/* Social Media Icons */}
                        <a href="https://www.instagram.com/pci_neikadesignz" className={styles.footerIconLink} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className={styles.footerIcon} />
                        </a>
                        <a href="https://www.tiktok.com/@pci_neikadesignz" className={styles.footerIconLink} aria-label="TikTok" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTiktok} className={styles.footerIcon} />
                        </a>
                        
                    </div>
                    <p className={styles.footerCopyright}>
                        © 2024 Personalized Cards & Invitations. All rights reserved.
                    </p>
                </div>
            </section>
        </Fragment>
    );
};

export default Footer;
