import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom'; 
import BackBtn from './BackBtn';
import Button from '../components/AddToCartBtn';
import CustomerRequestModal from '../components/CustomerRequestModal';
import inventoryData from '../data/inventoryData';
import FloatingCurrencyToggle from './FloatingCurrencyToggle/FloatingCurrencyToggle';
import styles from './Product.module.css'; 
import { fetchConversionRates } from './FloatingCurrencyToggle/ConversionRatesService'; // Import the service

const Product = () => {
    const [isCustomerRequestModalOpen, setIsCustomerRequestModalOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const [currency, setCurrency] = useState('USD');
    const [conversionRates, setConversionRates] = useState({
        USD_JMD: null,
        JMD_USD: null,
    });

    const openCustomerRequestModal = () => {
        setIsCustomerRequestModalOpen(true);
    };

    const closeCustomerRequestModal = () => {
        setIsCustomerRequestModalOpen(false);
    };

    const submittedCustomerRequestData = (data) => {
        console.log('prd', data);
    };

    const { productId, productTitle } = useParams();
    const [imageSelector, setImageSelector] = useState('');

    const imageSelectorHandler = (e, param) => {
        setImageSelector(param);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchRates = async () => {
            try {
                const rates = await fetchConversionRates();
                setConversionRates({
                    USD_JMD:  rates.USD_JMD, 
                    JMD_USD:  rates.JMD_USD,
                });
            } catch (error) {
                console.error('Error fetching conversion rates:', error);
            }
        };

        fetchRates();
    }, []);

    useEffect(() => {
        const savedCurrency = localStorage.getItem('currency');
        if (savedCurrency) {
            setCurrency(savedCurrency);
        }
    }, []);

    const handleCurrencyChange = (newCurrency) => {
        setCurrency(newCurrency);
        localStorage.setItem('currency', newCurrency);
    };

    const convertPrice = (price) => {
        if (conversionRates.USD_JMD === null || conversionRates.JMD_USD === null) {
            return price; // Return original price if rates are not available
        }
        return currency === 'USD' ? price * conversionRates.JMD_USD : price;
    };

    const formatCost = (cost) => {
        const options = {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        };
        return new Intl.NumberFormat('en-US', options).format(cost);
    };

    const currentData = inventoryData.find(data => data.id === productId && data.title === productTitle);

    if (!currentData) {
        return <p>Product not found</p>;
    }

    return (
        <Fragment>
            <main className={styles.container}>
                <div className={styles['left-column']}>
                    {isDesktop && <BackBtn />}
                    <img src={imageSelector || currentData.img1} alt="img" loading="lazy" />
                </div>
                <div className={styles['right-column']}>
                    <div className={styles['product-description']}>
                        <span>{currentData.category} / {currentData.title} / {currentData.id}</span>
                        <h1>{currentData.title}</h1>
                        <p>{currentData.desc}</p>
                    </div>
                    <div className={styles['product-configuration']}>
                        <div className={styles['product-color']}>
                            <p>Images</p>
                            <div className={styles['product-img-choose']}>
                                <img 
                                    id='img1' 
                                    className={styles['product-mini-img']} 
                                    src={currentData.img1} 
                                    alt='img1'
                                    onClick={e => imageSelectorHandler(e, currentData.img1)}  
                                    loading="lazy"
                                />
                                <img 
                                    className={styles['product-mini-img']} 
                                    src={currentData.img2} 
                                    alt='img2'
                                    onClick={e => imageSelectorHandler(e, currentData.img2)}  
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className={styles['include-config']}>
                            <p>This includes</p>
                            <div className={styles['include-choose']}>
                                <button disabled>{currentData.include}</button>
                            </div>
                            <div className={styles['product-description']}> 
                                <p>For a detailed quotation tailored to your needs, click "Request" and we’ll be happy to provide all the information you need!</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles['product-price']}>
                        <p>
                            {currency === 'USD' ? "USD $" : "JMD $"}
                            {formatCost(convertPrice(currentData.cost))}
                        </p>
                        <Button title='Request' onClick={openCustomerRequestModal} />
                        <CustomerRequestModal 
                            id={currentData.id} 
                            price={currentData.cost} 
                            name={currentData.title} 
                            isOpen={isCustomerRequestModalOpen} 
                            onClose={closeCustomerRequestModal}  
                            onSubmitCustomerRequestData={submittedCustomerRequestData} 
                        />
                    </div>
                </div>
            </main>
            <FloatingCurrencyToggle currency={currency} onCurrencyChange={handleCurrencyChange} />
        </Fragment>
    );
};

export default Product;
