import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import styles from './featuredCategoryCard.module.css';

const FeaturedCategoryCard = ({category}) => {

    return (
        <Fragment>
 <div className={styles['cards-container']}>
            <Link to={category.url} className={styles['featured-category-card-button-link']}>
                <div className={styles['featured-category-card']}>
                    <div className={styles['featured-card-content']}>
                        <img src={category.img} alt={category.categoryName} className={styles['featured-card-content']} loading="lazy"/>
                            <h3 className={styles['featured-category-card-button']}>
                                {category.categoryName}
                            </h3>
                    </div>
                </div>
            </Link>
            </div>
        </Fragment>
    );
};

export default FeaturedCategoryCard;
