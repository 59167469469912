import React from 'react';
import otherServicesProductCategory from '../../data/otherServices/otherServicesProductCategory';
import HeaderSection from '../../components/HeaderSection';
import ProductCategoryCarousel from '../../components/ProductCategoryCarousel';
import { Helmet } from 'react-helmet';

const OtherServices = () => {
    return (
        <div>
              <Helmet>
            <title>Learning Graphic Design - Personalized Cards and Invitations</title>
            <meta name="description" content="Boost your graphic design skills." />
            </Helmet>
            <HeaderSection title=' Learn Graphic Design'/>
            <ProductCategoryCarousel data={otherServicesProductCategory} header='Select a Category'/>
        </div>
    );
};

export default OtherServices;