import React from 'react';
import {useNavigate} from 'react-router-dom'; // Import useParams from React Router
import styles from './BackBtn.module.css';



const BackBtn = () => {

    const navigate = useNavigate();
    return (
        <div>
            <button onClick={()=>{navigate(-1)}} className={styles['back-btn']}>back</button>

        </div>
    );
};

export default BackBtn;