import React, { useState  } from 'react';
import styles from './CustomerRequestModal.module.css';
import emailjs from "@emailjs/browser";

const CustomerRequestModal = ({ id, name, price, isOpen, onClose, onSubmitCustomerRequestData}) =>
{

    const [loading, setLoading] = useState(false);

    const initialInputState = {
        name: name,
        id:id,
        price: price,
        itemQuantity: 0,
        customerContactNumber: '',
        customerName: '',
        customerEmail: '',
        itemName: name,
        itemCode: id,
        itemPrice: price,
        itemImg: 'https://images.unsplash.com/photo-1632610992723-82d7c212f6d7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80'
    };

    // Define regex patterns for email and phone number validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberPattern =/^\d{3}-\d{3}-\d{4}$/;


    // const [inputEmailFields,setinputEmailFields] = useState({...emailfields});
    const [inputState, setInputState] = useState({ ...initialInputState });
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

        // Function to format the input value
        const formatValue = (inputValue) => {
            // Remove all non-digit characters
            const digits = inputValue.replace(/\D/g, '');
    
            // Format the number with hyphens
            if (digits.length <= 3) {
                return digits;
            }
            if (digits.length <= 6) {
                return `${digits.slice(0, 3)}-${digits.slice(3)}`;
            }
            return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
        };
    // }
    
    const handleChange = (e) => {
        const { name,value } = e.target;

        if (name === "customerContactNumber"){
            setInputState((prevState) => ({ ...prevState, [name]: formatValue(value) }));
        };
        if (name !== "customerContactNumber")
            setInputState((prevState) => ({ ...prevState, [name]: value }));
      }; 

    const handleSubmit = async (e) => {

        e.preventDefault();
        setEmailError('');
        setPhoneError('');

       
        if (
            inputState.itemQuantity &&
            inputState.customerContactNumber &&
            inputState.customerEmail &&
            inputState.customerName
        ) {
            if (!emailPattern.test(inputState.customerEmail)) {
                setEmailError('Invalid email address');
                return;
            }

            if (!phoneNumberPattern.test(inputState.customerContactNumber)) {
                setPhoneError('Invalid phone number (000-000-0000)');
                return;
            }
            try {
                setLoading(true);
                    await emailjs.sendForm(                       
                        process.env.REACT_APP_EMAILJS_SERVICE_ID,process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                        e.target,
                        process.env.REACT_APP_EMAILJS_API_KEY).then(
                        (response) => {
                          console.log('SUCCESS!', response.status, response.text);
                        },
                        (error) => {
                          console.log('FAILED...', error);
                        },
                      );
                    setInputState({...initialInputState});
                alert("email successfully sent check inbox");
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
            onClose();
        }

    }

//
    const onCloose = () =>  {
    setInputState({...initialInputState});
    onClose();
    }


    return (
        isOpen && (
            <div className={styles.modal}>
                
                <div className={styles['modal-content']}>
                    <h2 className={styles['header']} >Request Quotation</h2>
                    <form onSubmit={handleSubmit}>
                    
                    <div className={styles.inputGroup}>
                        <div className={styles.inlineInput}>
                            <div className={styles.inlineInputItem}>
                                <label htmlFor='customerName'>Customer Name:</label>
                                <input
                                    id='customerName'
                                    type="text"
                                    name="customerName"
                                    required
                                    className={styles.inputField}
                                    value={inputState.customerName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.inlineInputItem}>
                                <label htmlFor='customerContactNumber'>Contact Number:</label>
                                <input
                                    id='customerContactNumber'
                                    type="tel"
                                    placeholder='876-123-1234'
                                    required
                                    className={styles.inputField}
                                    name="customerContactNumber"
                                    onChange={handleChange}
                                    value={inputState.customerContactNumber}
                                />
                                <p className={styles.errorText}>{phoneError}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.inlineInput}>
                            <div className={styles.inlineInputItem}>
                                <label htmlFor='itemQuantity'>Quantity:</label>
                                <select
                                    id='itemQuantity'
                                    required
                                    className={styles.selectInputField}
                                    value={inputState.itemQuantity}
                                    name="itemQuantity"
                                    onChange={handleChange}
                                >
                                    <option value="">Select an option</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="35">35</option>
                                    <option value="40">40</option>
                                    <option value="45">45</option>
                                    <option value="50">50</option>
                                    <option value="60">60</option>
                                    <option value="70">70</option>
                                    <option value="80">80</option>
                                    <option value="90">90</option>
                                    <option value="100">100</option>
                                </select>
                            </div>

                            <div className={styles.inlineInputItem}>
                                <label htmlFor='customerEmail'>Email Address:</label>
                                <input
                                    id='customerEmail'
                                    type="email"
                                    required
                                    className={styles.inputField}
                                    name="customerEmail"
                                    value={inputState.customerEmail}
                                    onChange={handleChange}
                                />
                                <p className={styles.errorText}>{emailError}</p>
                            </div>
                        </div>
                    </div>
                                    <input
                                    id='customerName'
                                    type="text"
                                    name="name"
                                    value={inputState.name}
                                    onChange={handleChange}
                                    hidden
                                    />
                                    <input
                                    id='prodId'
                                    type="text"
                                    name="prodId"
                                    value={inputState.id}
                                    hidden
                                    onChange={handleChange}
                                    />
                                    <input
                                    id='price'
                                    type="text"
                                    name="price"
                                    value={inputState.price}
                                    hidden
                                    onChange={handleChange}
                                    />

                    <button type="submit" className={styles.submitButton} disabled={loading}>Done</button>
                    <button className={styles.closeButton} onClick={onCloose}>Cancel</button>
                 </form>
                 </div>
               
            </div>
        )
    );
};

export default CustomerRequestModal;
