import React from 'react';
import aboutUs from '../../assets/images/aboutUs1.png';
import styles from './WhatSetsUsApart.module.css';
const WhatSetsUsApart = () => {
    return (
        <div className={styles.heroContainer}>
            <div className={styles.textContainer}>
                <h1 className={styles.headerText}>What Sets Us Apart?</h1>
                <ul>
                    <li className={styles.subText }>
                        <span><b>Creativity: </b></span>
                        We pour our creativity into every detail, ensuring that your stationery reflects your unique style and personality.
                    </li>
                    <li className={styles.subText}>
                        <span><b>Quality: </b> </span>
                        We take pride in using the finest materials to craft stationery that's not only beautiful but also durable and long-lasting.
                    </li>
                    <li className={styles.subText}>
                        <span><b>Customer Experience: </b>  </span>
                        Your happiness is our top priority. We're here to guide you through the design process, answer your questions, and ensure you have a seamless experience from start to finish.
                    </li>
                </ul>
            </div>
            <div className={styles.imageContainer}>
                <img src={aboutUs} alt="Hero" className={styles.heroImage}  loading="lazy"/>
            </div>
        </div>
    );
};

export default WhatSetsUsApart;