import React from 'react';
import corporateProductCategory from '../../data/corporate/corporateProductCategory';
import HeaderSection from '../../components/HeaderSection';
import ProductCategoryCarousel from '../../components/ProductCategoryCarousel';
import { Helmet } from 'react-helmet';

const Corporate = () => {
    return (
        <div>
             <Helmet>
            <title>Business Card and Website Development - Personalized Cards and Invitations</title>
            <meta name="description" content="Enhance your brand with our Business Card and Website Development services at Personalized Cards and Invitations. We offer custom design solutions for impactful business cards and professional websites that help you stand out." />
        </Helmet>
            <HeaderSection title='corporate'/>
            <ProductCategoryCarousel data={corporateProductCategory} header='Select a Category'/>
        </div>
    );
};

export default Corporate;