import { Link } from 'react-router-dom';
import './OpeningHours.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const OpeningHours = ()=>{
  return (
    <div id="contact">
      <div className="container">
        <div className="contact-opening-container">
          <div className="col-md-7">
            <h2 className='textheader'>Contact us</h2>
            <Link
              to="https://wa.me/18762382419"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-custom"
            >
              Whatsapp
            </Link>
          </div>
          <div className="col-md-5">
            <h2 className='textheader'>Opening Hours</h2>
            <h4 className='sectiontext'><span>Mon: </span> 10:00am – 6:00pm</h4>
            <h4 className='sectiontext'><span>Tue: </span> 10:00am – 6:00pm</h4>
            <h4 className='sectiontext'><span>Wed: </span> 10:00am – 6:00pm</h4>
            <h4 className='sectiontext'><span>Thu: </span> 10:00am – 6:00pm</h4>
            <h4 className='sectiontext'><span>Fri: </span> 10:00am – 6:00pm</h4>
            <h4 className='sectiontext'><span>Sat & Sun: </span> Closed</h4>
          </div>
        </div>

        <div className="email-section">
          <div className="social">
            <h2 className='textheader'>Follow us on social media:</h2>
            <ul className="social-list">
              <li className="social-list-item">
                <a target="_blank" rel="noreferrer" aria-label="PCI Instagram Page" href='https://www.instagram.com/pci_neikadesignz/' className='iconLinks'>
                  <span className='icon'><FontAwesomeIcon icon={faInstagram} /></span>
                </a>
              </li>
              <li className="social-list-item">
                <a target="_blank" rel="noreferrer"  aria-label="PCI Google Page" href='https://personalizedcardsandinvitations.business.site/?utm_source=gmb&utm_medium=referral' className='iconLinks'>
                  <span className='icon'><FontAwesomeIcon icon={faGoogle} /></span>
                </a>
              </li>
              <li className="social-list-item">
                <a target="_blank" rel="noreferrer"  aria-label="PCI TikTok Page" href='https://www.tiktok.com/@pci_neikadesignz' className='iconLinks'>
                  <span className='icon'><FontAwesomeIcon icon={faTiktok} /></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
 </div>
    </div>
  );
};

export default OpeningHours;
