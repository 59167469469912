import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './ShippingPolicy.module.css';
import { Helmet } from 'react-helmet';


const ShippingPolicy = () => {
    return (
        <div>
            <Helmet>
            <title> Shipping Policy - Personalized Cards and Invitations</title>
            <meta name="description" content="Learn about our Shipping Policy at Personalized Cards and Invitations. Discover shipping rates, delivery times, and how we handle your orders to ensure timely and secure delivery of your custom cards and invitations." />
   </Helmet>
            <HeaderSection title='Shipping Policy'/>
            <div className={styles.policyContainer}>
                <section className={styles.section}>
                    <h2 className={styles.shippingHeader}>1. Shipping Methods</h2>
                    <p>
                        We offer reliable and convenient shipping options for your orders:
                    </p>
                    <ul>
                        <li><strong>Knutsford Courier Service</strong> </li>
                        <li><strong>Bearer Service</strong> </li>
                        <li><strong>Tara Courier Service</strong> </li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.shippingHeader}>2. Delivery Times</h2>
                    <p>
                        Delivery times may vary depending on several factors:
                    </p>
                    <ul>
                        <li><strong>Location</strong>  <br></br>The delivery time may vary based on your location.</li>
                        <br></br>
                        <li><strong>Product</strong>  <br></br>The type of product ordered can impact the delivery time, especially if it involves custom design and crafting.</li>
                        <br></br>
                        <li><strong>Quantity</strong>  <br></br>Larger orders or orders with a higher quantity of items may require additional processing and delivery time.</li>
                    </ul>
                    <p>
                        During the ordering process, we will provide estimated delivery times based on your specific order details.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.shippingHeader}>3. Delivery Process</h2>
                    <p>
                        Our delivery process is designed to ensure that your orders reach you in the best possible condition:
                    </p>
                    <ul>
                        <li><strong>Estimates</strong> <br></br> We aim to meet the estimated delivery times provided during the ordering process. However, please understand that occasional delays may occur due to unforeseen circumstances.</li>
                        <br></br>
                        <li><strong>Delivery Partners</strong>  <br></br>We utilize trusted courier services such as Bearer Service, Knutsford Courier Services and Tara Courier Service to handle the safe and timely delivery of your orders.</li>
                        <br></br>
                        <li><strong>Schedule</strong>  <br></br>For your convenience, you will receive a delivery schedule once your order is ready for delivery, allowing you to know the date and time for delivery of your package.</li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.shippingHeader}>4. Shipping Charges</h2>
                    <p>
                        Shipping charges are calculated based on several factors, including the destination, the size and weight of the package, and the chosen shipping method.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.shippingHeader}>5. Order Confirmation and Receipt</h2>
                    <p>
                        Upon placing your order, you will receive a receipt via email/whatsapp that includes the details of your purchase.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.shippingHeader}>6. Contact Us</h2>
                    <p>
                        If you have any questions or concerns about our shipping policy or the delivery of your order, please feel free to contact us for assistance. Our customer representatives are here to help you.
                    </p>
                </section>

                <p>
                    Thank you for choosing PC&I for your stationery needs. We look forward to delivering quality products to you in a timely and reliable manner.
                </p>
            </div>
        </div>
    );
};

export default ShippingPolicy;




