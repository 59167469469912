import React from 'react';
import styles from './Btn.module.css';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
const WhatsappBtn = ({title}) => {
    const phoneNumber = 18762382419;
    // Function to handle opening WhatsApp chat
    const openWhatsAppChat = () => {
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank'); // Open in a new tab or window
    }
    return (
        <div>
            <button className={styles['white-no-bg']}  onClick={openWhatsAppChat}>{title} <span><FontAwesomeIcon icon={faWhatsapp} /></span></button>

        </div>
    );
};

export default WhatsappBtn;