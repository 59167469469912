import React, { useState } from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './Blog.module.css';
import { Helmet } from 'react-helmet';

// Sample blog data
const blogs = [
    {
        id: 1,
        image: './../../../Other/blog_header_1.jpg',
        header: `Congrats, You're Finally Engaged! Now What?`,
        text: 'Planning a wedding can be overwhelming, but a well-organized checklist makes it manageable! This detailed guide will take you through every step from your engagement to your wedding day',
        content: `
        <div>
        <p>Congratulations on your engagement! The excitement of this moment is just the beginning. Here’s what you should focus on next:</p>
    
        <h4>Set a Budget</h4>
        <p>Start by setting a realistic budget. Discuss with your partner how much you’re willing to spend and allocate funds accordingly. This will guide all your decisions moving forward.</p>
        <h4>Create a Guest List</h4>
        <p>Draft a preliminary guest list to get an idea of the size of your wedding. Whether you envision a large celebration or an intimate gathering, knowing your guest count will help with other planning aspects.</p>
      
        <h4>Choose a Date and Venue</h4>
        <p>Select a wedding date and secure your venue as soon as possible. Consider the season, availability of key guests, and any special dates that matter to you. Popular venues can book up quickly, so act fast.</p>
      
        <h4>Decide on DIY or Planner</h4>
        <p>Consider whether you want to hire a wedding planner or handle the details yourself. A planner can ease stress and provide expertise, while a DIY approach can offer personal touches and control over every detail.</p>
      
        <h4>Get Our Wedding Checklist</h4>
        <p>Stay organized with a comprehensive wedding checklist. This will keep you on track and ensure you don’t miss any important steps along the way.</p>
      
        <h4>Enjoy the Journey</h4>
        <p>Amidst all the planning, don’t forget to enjoy this special time. Take breaks, spend quality moments with your partner, and relish the excitement of planning your wedding.</p>
      
        <p>By focusing on these key steps, you’ll be well on your way to planning a memorable and beautiful wedding. Congratulations again, and happy planning!</p>
      </div>
        `,
    },
    {
        id: 2,
        image: './../../../Other/blog_header_2.jpg',
        header: 'The Ultimate Guide to Wedding Checklists',
        text: 'Simplify your planning with these checklists',
        content: `
        <div>
  <h3>Simplify your planning with these checklists</h3>
  <p>Planning a wedding can be overwhelming, but a detailed checklist can make the process smoother. Here’s your ultimate guide:</p>
  
  <h3>12-18 Months Before:</h3>
  <ul>
    <li>Set a budget</li>
    <li>Create a guest list</li>
    <li>Choose and book your venue</li>
    <li>Hire a wedding planner (optional)</li>
  </ul>
  
  <h3>9-12 Months Before:</h3>
  <ul>
    <li>Select your bridal party</li>
    <li>Book key vendors (photographer, caterer, etc.)</li>
    <li>Send save-the-dates</li>
    <li>Start shopping for attire</li>
  </ul>
  
  <h3>6-9 Months Before:</h3>
  <ul>
    <li>Order invitations - <a href="/wedding/invitations">browse our invitation catalog</a></li>
    <li>Plan your honeymoon</li>
    <li>Create a wedding website</li>
    <li>Register for gifts</li>
  </ul>
  
  <h3>3-6 Months Before:</h3>
  <ul>
    <li>Finalize the guest list</li>
    <li>Send invitations</li>
    <li>Arrange transportation</li>
    <li>Choose decorations</li>
  </ul>
  
  <h3>1-3 Months Before:</h3>
  <ul>
    <li>Confirm details with vendors</li>
    <li>Create a day-of timeline</li>
    <li>Attend final fittings</li>
    <li>Apply for a marriage license</li>
  </ul>
  
  <h3>1-4 Weeks Before:</h3>
  <ul>
    <li>Send final payments</li>
    <li>Confirm RSVPs</li>
    <li>Prepare a seating chart</li>
    <li>Pack for the honeymoon</li>
  </ul>
  
  <p>Stay organized with this checklist and enjoy the planning journey!</p>
</div>
        `,
    },
    {
        id: 3,
        image: './../../../Other/blog_header_3.jpg',
        header: '5 Essential Conversations Before Tying the Knot',
        text: `It's important to have these converations with your partner`,
        content: 
        `
        <div>
  <h3>Open communication is key to a strong marriage. Here are five crucial topics to discuss:</h3>
  
  <h3>1. Financial Goals:</h3>
  <p>Budgeting, debt, and future plans</p>
  
  <h3>2. Family Planning:</h3>
  <p>Children, parenting styles, and family involvement</p>
  
  <h3>3. Career Goals:</h3>
  <p>Career aspirations, support, and work-life balance</p>
  
  <h3>4. Household Responsibilities:</h3>
  <p>Division of chores, living arrangements, and home maintenance</p>
  
  <h3>5. Conflict Resolution:</h3>
  <p>Communication styles, handling disagreements, and compromise</p>
  
  <p>Having these conversations can build a solid foundation for your marriage.</p>
</div>
        `,
    },
    {
        id: 4,
        image: './../../../Other/blog_header_4.jpg',
        header: `Wedding Stationery Must-Haves and When to Order Them`,
        text: `Essential wedding stationery and their timelines`,
        content: `
        <div>
  <h3>Essential wedding stationery and their timelines:</h3>
  
  <h3>Save-the-Dates:</h3>
  <p>Order: 10-12 months before<br>Send: 6-12 months before</p>
  
  <h3>Invitations:</h3>
  <p>Order: 6-9 months before<br>Send: 6-8 weeks before (3-4 months for destination weddings)</p>
  
  <h3>RSVP Cards and Details Cards:</h3>
  <p>Order: With invitations<br>Send: With invitations</p>
  
  <h3>Programs, Menus, and Place Cards:</h3>
  <p>Order: 2-3 months before<br>Distribute: At the ceremony/reception</p>
  
  <h3>Thank-You Cards:</h3>
  <p>Order: Before the wedding<br>Send: Within 3 months after the wedding</p>
  
  <p>Stay organized and ensure timely delivery with this timeline.</p>
</div>`,
    },
   
];

const Blog = () => {
    const [selectedBlog, setSelectedBlog] = useState(blogs[0]);

    const handleCardClick = (id) => {
        const blog = blogs.find(b => b.id === id);
        if (blog) {
            setSelectedBlog(blog);
            window.scrollTo(0, 0); // Scroll to top of the page when changing blog
        }
    };

    const truncateContent = (content) => {
        // Strip HTML tags
        const text = content.replace(/<\/?[^>]+(>|$)/g, " ");
        return text.length > 100 ? text.substring(0, 100) + '...' : text;
    };

    return (
        <div>
            <Helmet>
                <title>Personalized Cards and Invitations - Blog</title>
                <meta name="description" content="Discover expert tips and advice for brides and grooms. Our blog offers guidance on wedding planning, stationery choices, and everything you need for your special day." />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap" />
            </Helmet>
            <HeaderSection title="Neika's Wedding Corner" />
            <div className={styles.container}>
                <div className={styles.blogContent}>
                    <div className={styles.mainBlog}>
                        <img src={selectedBlog.image} alt="Blog" className={styles.blogImage} />
                        <h2 className={styles.blogHeader}>{selectedBlog.header}</h2>
                        <div className={styles.blogText} dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />
                    </div>
                </div>
                <div className={styles.blogCards}>
                    {blogs.map(blog => (
                        <div
                            key={blog.id}
                            className={styles.blogCard}
                            onClick={() => handleCardClick(blog.id)}
                        >
                            <img src={blog.image} alt={blog.header} className={styles.cardImage} />
                            <div className={styles.cardContent}>
                                <h4 className={styles.cardHeader}>{blog.header}</h4>
                                <p className={styles.cardText}>
                                    {truncateContent(blog.content)}
                                    <span className={styles.viewMore}> Read More</span>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Blog;
