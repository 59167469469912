import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import styles from './ProductCard.module.css';


const ProductCategoryCard = ({product}) => {
  
    return (
        <Fragment> 
            <Link to={product.title} className={styles['product-card-button-link']}>
                <div className={styles['product-card']}>
                    <div className={styles['card-content']}>
                        <img src={product.src} alt={product.title} className={styles['card-content']}  loading="lazy"/>
                        <div className={styles['product-card-button-link']}>
                            <h3 className={styles['product-card-button']}>
                            {product.title.toUpperCase()}
                            </h3>
                        </div>
                    </div>
                </div>
            </Link>
        </Fragment> 
    );
};

export default ProductCategoryCard;
