import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './FormList.module.css';
import { Helmet } from 'react-helmet';

const FormList = () => {
    return (
        <div>
            <Helmet>
            <title>List of Forms - Personalized Cards and Invitations</title>
            <meta name="description" content="This is a list of forms to be filled by a customer based on the product they order." />
        </Helmet>
            <HeaderSection title='Form List'/>
            <div className={styles.formListContainer}>
                <section className={styles.formListsection}>
                    <h2 className={styles.formListHeader}>Wedding<hr></hr></h2>
                    <b><p>       List of wedding forms:   </p></b>
                            <a href='https://forms.gle/BwTtmUazVn8Cp2iJ9' target='_blank' rel="noreferrer" ><p className={styles.listText}>Invitation</p> </a>
                            <a href='https://forms.gle/hpD5JZdAgM5WM9LT8' target='_blank' rel="noreferrer"> <p className={styles.listText}>Programme</p></a>
                            <a href='https://forms.gle/ChCEjSAqHNjyEeC58' target='_blank' rel="noreferrer"> <p className={styles.listText}>Souvenir</p></a>
                            <a href='https://forms.gle/3xQCWmVcGEF91gQc6' target='_blank' rel="noreferrer"> <p className={styles.listText}>Menu Card</p></a>
                </section>

                <section className={styles.formListsection}>
                    <h2 className={styles.formListHeader}>Corporate<hr></hr></h2>
                    <b><p>       List of corporate forms:   </p></b>
                            <a href='https://forms.gle/65pQydNefvt9JhCCA' target='_blank' rel="noreferrer"><p className={styles.listText}>Business Card</p> </a>
                            <a href='https://forms.gle/Y6s9tjeNY1KdyDnW9' target='_blank' rel="noreferrer"><p className={styles.listText}>Calendar</p> </a>
                            <a href='https://forms.gle/BwTtmUazVn8Cp2iJ9' target='_blank' rel="noreferrer"><p className={styles.listText}>Invitation</p> </a>
                            <a href='https://forms.gle/hpD5JZdAgM5WM9LT8' target='_blank' rel="noreferrer"> <p className={styles.listText}>Programme</p></a>
                            <a href='https://forms.gle/ChCEjSAqHNjyEeC58' target='_blank' rel="noreferrer"> <p className={styles.listText}>Souvenir</p></a>
                            <a href='https://forms.gle/3xQCWmVcGEF91gQc6' target='_blank' rel="noreferrer"> <p className={styles.listText}>Menu Card</p></a>
                </section>
            </div>
        </div>
    );
};

export default FormList;