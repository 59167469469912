import React, {Fragment} from 'react';
import styles from './HeaderSection.module.css';

const HeaderSection = (props) => {
    return (
        <Fragment>
        <div className={styles.header}>
            <h1 className={styles['header-text']}>{props.title}</h1>
            <h1 className={styles['header-body-text']}>{props.body}</h1>
        </div>
        </Fragment>
    );
};

export default HeaderSection;