const inventoryData = [
    {
        id: '1',
        img1: "../../Wedding/Menu/60.png",
        img2: "../../Wedding/Menu/59.png",
        alt: "Metallic Menu Card",
        title: "Metallic Menu Card",
        cost: '200',
        include: 'A high-quality menu card with a metallic finish, showcasing your menu items in style',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Flat Menu Card",
        desc: 'Add a touch of glamour to your dining experience with our Metallic Menu Card. Featuring a sleek, reflective finish, this card elevates your menu presentation, making it perfect for upscale events, weddings, or special dinners.',
        url: "/wedding",
    }, {
        id: '2',
        img1: "../../Wedding/Menu/59.png",
        img2: "../../Wedding/Menu/60.png",
        alt: "Plain Menu Card",
        title: "Plain Menu Card",
        cost: '185',
        include: 'High-quality menu card ready for your custom menu details',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Flat Menu Card",
        desc: 'Designed for simplicity and elegance, this card provides a clean and straightforward way to present your menu items, making it ideal for any event or dining experience.',
        url: "/wedding",
    }, {
        id: '3',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Arch Menu Card",
        title: "Metallic Arch Menu Card",
        cost: '235',
        include: 'A high-quality menu card with a metallic finish, showcasing your menu items in style.',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Arch Menu Card",
        desc: 'Add a touch of glamour to your dining experience with our Metallic Menu Card. Featuring a sleek, reflective finish, this card elevates your menu presentation, making it perfect for upscale events, weddings, or special dinners.',
        url: "/wedding",
    }, {
        id: '4',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",
        alt: "Arch Menu Card",
        title: "Plain Arch Menu Card",
        cost: '220',
        include: 'A high-quality menu card with a metallic finish, showcasing your menu items in style.',
        eventType: "wedding",
        category: "menu card",
        subCategory: "Arch Menu Card",
        desc: 'Captivate your guests with this beautiful arch plain wedding menu card.',
        url: "/wedding",
    },
    {
        id: '61',
        img1: "../../Wedding/Invite/19.png",
        img2: "../../Wedding/Invite/19.png",
        alt: "Animated Digital Invitation",
        title: "Animated MP4 Invite",
        cost: '14499',
        include: 'Invitation, Reception, RSVP & Registry QRCode',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Digital Wedding Invitation",
        desc: 'Share your special day with our high-resolution digital animated invitation, '+
                'showcasing a dynamic design that includes your invite, '+
                'RSVP, reception details, and registry QR codes if needed.',
        url: "/wedding",
    },  
    {
        id: '5',
        img1: "../../Wedding/Invite/70.png",
        img2: "../../Wedding/Invite/70.png",
        alt: "Metallic Invitation Suite",
        title: "Metallic Invitation Suite",
        cost: '945',
        include: 'Invitation, Details and RSVP Card, RSVP Envelope, and Invitation Envelope',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Metallic Invitation",
        desc: 'Invite with style with this elegant set that includes your invitation, details and RSVP card, along with coordinating RSVP and invitation envelopes for a seamless, stylish presentation.',
        url: "/wedding",
    },
    {
        id: '6',
        img1: "../../Wedding/Invite/80.png",
        img2: "../../Wedding/Invite/80.png",
        alt: "Metallic Flat Invitation",
        title: "Metallic Flat Invitation",
        cost: '365',
        include: 'Invitation & Envelope',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Metallic Invitation",
        desc: 'Celebrate with flair using our stunning new wedding invitation, featuring one elegant invite and a coordinating envelope.',
        url: "/wedding",
    },
    {
        id: '7',
        img1: "../../Wedding/Shirt/33.png",
        img2: "../../Wedding/Shirt/30.png",  
        alt: "shirt",
        title: "limited Edition Shirt",
        cost: '6999',
        include: 'Two Shirts',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
    },
    {
        id: '8',
        img1: "../../Wedding/Shirt/12.png",
        img2: "../../Wedding/Shirt/12.png",  
        alt: "shirt",
        title: "shirt",
        cost: '6999',
        include: 'Two Custom Shirt',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
    }, 
    {
        id:'8',
        img1: "../../Wedding/Shirt/13.png",
        img2: "../../Wedding/Shirt/13.png",  
        alt: "shirt",
        title: "shirt",
        cost: '6999',
        include: 'Two Custom Shirt',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
    },
    {
        id: '10',
        img1: "../../Wedding/Invite/22.png",
        img2: "../../Wedding/Invite/21.png",
        alt: "Laser Cut Pocket",
        title: "Laser Cut Pocket",
        cost: '660',
        include: 'Invitation, Detail Card, and Coordinating  Pocket',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Pocket Invitation",
        desc: 'Invite with style using our stunning metallic laser cut pocket invitation, featuring an elegant invite and a detail card with reception and RSVP information, all beautifully held in a sleek, coordinating pocket.',
        url: "/wedding",
    },
    {
        id: '11',
        img1: "../../Wedding/Invite/23.png",
        img2: "../../Wedding/Invite/24.png",
        alt: "Laser Cut Pocket Fold Invitation",
        title: "Laser Cut Pocket Fold",
        cost: '875',
        include: 'Invitation, Reception & RSVP Details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Pocket Invitation",
        desc: 'Delight in our luxurious metallic pocket fold laser cut wedding invitation, which includes an elegant invite and a detail card with reception and RSVP information.',
        url: "/wedding",
    },
    {
        id: '12',
        img1: "../../Wedding/Invite/71.png",
        img2: "../../Wedding/Invite/71.png",
        alt: "Plain Pocket Fold",
        title: "Plain Pocket Fold Invitation",
        cost: '875',
        include: 'Invitation, Reception & RSVP Details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Pocket Invitation",
        desc: 'Invite with passion using our tri-fold wedding invitation, featuring an elegant invite along with a detail card that includes reception and RSVP information.',
        url: "/wedding",
    }, 
    {
        id: '13',
        img1: "../../Wedding/Invite/18.png",
        img2: "../../Wedding/Invite/19.png",
        alt: "Pocket Invitation",
        title: "JPG Invite",
        cost: '5000',
        include: 'Digital Wedding Invitation with Reception & RSVP Details',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Digital Wedding Invitation",
        desc: 'Share your special day with our digital invitation, featuring a beautifully designed image that includes all your reception and RSVP details.',
        url: "/wedding",
    }, 
    {
        id: '22',
        img1: "../../Wedding/Invite/27.png",
        img2: "../../Wedding/Invite/26.png",
        alt: "Slider Invitation",
        title: "Slider Invitation",
        cost: '945',
        include: 'Invite, Reception Details, RSVP Details & Gift Registry Card',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Slider Invitation",
        desc: 'Create a whimsical and unforgettable invite with our Slider Invitation featuring a moving bride and groom. Pull the slider to reveal a charming animation of the bride meeting the groom, adding a playful and personalized touch to your event. Perfect for weddings and engagement parties..',
        url: "/wedding",
    }, 
    {
        id: '14',
        img1: "../../Wedding/Invite/65.png",
        img2: "../../Wedding/Invite/1.png",
        alt: "Gold Foil Boarding Pass ",
        title: "Gold Foil Boarding Pass",
        cost: '1000',
        include: 'Boarding pass with invitation, reception and RSVP details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Ticket Invitation",
        desc: 'Invite with grace with the new gold foil boarding pass wedding invitation. This invite is consealed with a golden string.',
        url: "/wedding",
    }, 
    {
        id: '15',
        img1: "../../Wedding/Invite/28.png",
        img2: "../../Wedding/Invite/2.png",
        alt: "Plain Boarding Pass Invitation",
        title: "Flat Boarding Pass",
        cost: '620',
        include: 'Boarding pass with invitation, reception and RSVP details.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Ticket Invitation",
        desc: 'Invite with grace with the new plain boarding pass wedding invitation. This invite is consealed with a golden string',
        url: "/wedding",
    },  
    {
        id: '16',
        img1: "../../Wedding/PlaceCard/77.png",
        img2: "../../Wedding/PlaceCard/77.png", alt: "Place Card",
        title: "Plain Place Card",
        cost:'0',
        include: 'Plain Place Card',
        eventType: "wedding",
        category: "place card",
        subCategory: "Plain Place Card",
        desc: 'Add a touch of sophistication to your wedding reception with our Elegant Plain Place Cards. Crafted from high-quality, durable materials, these cards feature a clean, minimalist design that effortlessly complements any wedding theme. ting with style',
        url: "/wedding",
    }, {
        id: '17',
        img1: "../../Wedding/PlaceCard/77.png",
        img2: "../../Wedding/PlaceCard/77.png", 
        alt: "Place Card",
        title: "Metallic Place Card",
        cost: '125',
        include: 'A metallic-finished place card, ideal for displaying guest names and table numbers in style.',
        eventType: "wedding",
        category: "place card",
        subCategory: "Plain Place Card",
        desc: 'Add a touch of luxury to your event with our Metallic Place Card. Featuring a shimmering, reflective finish, this card enhances your table settings with elegance and sophistication, perfect for weddings, corporate events, or upscale gatherings.',
        url: "/wedding",
    }, 
    {
        id: '18',
        img1: "../../Wedding/Box/17.png",
        img2: "../../Wedding/Box/18.png", 
        alt: "Cake box",
        title: "Butterfly Cake Box",
        cost: '180',
        include: 'A cake box with a decorative butterfly design, designed to safely and elegantly hold your cakes.',
        eventType: "wedding",
        category: "box",
        subCategory: "Cake Boxes",
        desc: 'Present your cakes with a touch of whimsy using our Butterfly Cake Box. This L3.5" x W2" x H2.55 butterfly box adds a delightful flair to your cakes, making it perfect for celebrations, gifts, or special occasions',
        url: "/wedding",
    }, 
    {
        id: '19',
        img1: "../../Wedding/Box/16.png",
        img2: "../../Wedding/Box/16.png", 
        alt: "CupCake box",
        title: "CupCake Box",
        cost: '190',
        include: 'Box & Custom Stickers',
        eventType: "wedding",
        category: "box",
        subCategory: "CupCake Boxes",
        desc: 'Dimensions: L3.25" x W3.25" x H2.75" ',
        url: "/wedding",
    }, 
    {
        id: '20',
        img1: "../../Wedding/Box/15.png",
        img2: "../../Wedding/Box/4.png", 
        alt: "Handbag Box",
        title: "Handbag Box",
        cost: '180',
        include: 'Box & Custom Stickers. You may request ribbons as an add on at an additional cost.',
        eventType: "wedding",
        category: "box",
        subCategory: "Favor Boxes",
        desc: 'Hand your favor gifts to your guests in style with this luxurious handbag favour box.',
        url: "/wedding",
    }, 
    {
        id: '21',
        img1: "../../Wedding/Box/19.png",
        img2: "../../Wedding/Box/20.png", 
        alt: "Custom Square Ribbon Favor box",
        title: "Square Ribbon Favor Box",
        cost: '260',
        include: 'Get your favor boxes designed to your unique wedding theme.',
        eventType: "wedding",
        category: "box",
        subCategory: "Favor Boxes",
        desc: 'Ribbon, Box, Custom Stickers.',
        url: "/wedding",
    }, 
    
    {
        id: '23',
        img1: "../../Wedding/Invite/46.png",
        img2: "../../Wedding/Invite/46.png",
        alt: "Arch Slider Invitation",
        title: "Arch Slider Invitation",
        cost: '795',
        include: 'An arch-shaped invitation with a sliding panel for custom details and a stylish presentation.',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Slider Invitation",
        desc: 'Make a memorable impression with our Arch Slider Invitation. This elegantly designed invitation features a unique sliding mechanism within an arch-shaped frame, offering a sophisticated and interactive touch. Perfect for weddings, anniversaries, or special events.',
        url: "/wedding",
    }, 
    {
        id: '24',
        img1: "../../Wedding/Souvenir/9.png",
        img2: "../../Wedding/Souvenir/38.png", 
        alt: "Stainless Steel Calendar Keychain",
        title: "Stainless Steel Calendar Keychain",
        cost: '2500',
        include: 'A stainless steel keychain with an engraved, functional calendar.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Keep your important dates close at hand with our Stainless Steel Calendar Keychain. This sleek, durable keychain features a finely engraved calendar, allowing you to mark and track your special occasions wherever you go.',
        url: "/wedding",
    },
    {
        id: '25',
        img1: "../../Wedding/Souvenir/52.png",
        img2: "../../Wedding/Souvenir/81.png", 
        alt: "Heart Acrylic Keychain",
        title: "Heart Acrylic Keychain",
        cost: '520',
        include: 'A heart-shaped acrylic keychain with a clear photo window.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Celebrate your cherished memories with our Heart Acrylic Photo Keychain. This beautifully crafted keychain features a heart-shaped acrylic charm, offering a clear, stylish display for your favorite photo. ',
        url: "/wedding",
},
{
    id: '26',
    img1: "../../Wedding/Souvenir/53.png",
    img2: "../../Wedding/Souvenir/53.png", 
    alt: "Square Acrylic Keychain",
    title: "Square Acrylic Keychain",
    cost: '550',
    include: 'A square-shaped acrylic keychain with a clear photo window.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Key Chain",
    desc: 'Celebrate your cherished memories with our Square Acrylic Photo Keychain. This beautifully crafted keychain features a square-shaped acrylic charm, offering a clear, stylish display for your favorite photo. ',
    url: "/wedding",
},
{
    id: '27',
    img1: "../../Wedding/Souvenir/52.png",
    img2: "../../Wedding/Souvenir/52.png", 
    alt: "Bottle Opener Acrylic Keychain",
    title: "Bottle Opener Acrylic Keychain",
    cost: '550',
    include: 'An acrylic keychain with an integrated bottle opener.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Key Chain",
    desc: 'Combine convenience with style using our Bottle Opener Acrylic Keychain. This sleek, durable keychain features a built-in bottle opener, making it perfect for on-the-go refreshment. A practical accessory with a modern twist!',
    url: "/wedding",
},  
{
    id: '28',
    img1: "../../Wedding/Souvenir/51.png",
    img2: "../../Wedding/Invite/51.png", 
    alt: "Custom Wine Label",
    title: "Custom Wine Label",
    cost: '500',
    include: 'A personalized adhesive wine label with your custom design.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Label",
    desc: 'Personalize with your own design or message to create a unique and memorable touch for any occasion. Ideal for gifts, celebrations, or special events, it adds a personal flair to your favorite bottles.',
    url: "/wedding",
}, {
    id: '29',
    img1: "../../Wedding/Souvenir/10.png",
    img2: "../../Wedding/Souvenir/10.png", 
    alt: "Custom Bottle Label",
    title: "Custom Bottle Label",
    cost: '500',
    include: 'A high-quality, adhesive bottle label customizable with your design or text',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Label",
    desc: 'Add a personal touch to your bottles with our Custom Bottle Label. Perfect for any occasion, this label lets you showcase your unique design or message, transforming ordinary bottles into memorable keepsakes or gifts.',
    url: "/wedding",
},{
    id: '30',
    img1: "../../Wedding/Box/8.png",
    img2: "../../Wedding/Box/7.png", 
    alt: "Square Ribbon Pearl Favor box",
    title: "Square Ribbon Pearl Favor Box",
    cost: '225',
    include: 'A square favor box with a decorative ribbon and pearl accent, perfect for holding small gifts or treats.',
    eventType: "wedding",
    category: "box",
    subCategory: "Favor Boxes",
    desc: 'Elegantly present your favors with our Square Ribbon Pearl Favor Box. This charming box features a classic square shape adorned with a delicate ribbon and a lustrous pearl detail, making it a sophisticated choice for weddings, showers, or special events.',
    url: "/wedding",
},  {
    id: '31',
    img1: "../../Wedding/Souvenir/66.png",
    img2: "../../Wedding/Souvenir/79.png", 
    alt: "Bottle Opener Nail Clipper",
    title: "Bottle Opener Nail Clipper",
    cost: '550',
    include: 'A compact nail clipper with an integrated bottle opener, designed for easy use and portability.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Key Chain",
    desc: 'Get double the utility with our Bottle Opener Nail Clipper. This versatile tool combines a sleek nail clipper with a handy bottle opener, making it a practical accessory for everyday use. Perfect for those who appreciate both functionality and convenience.',
    url: "/wedding",
},
{
    id: '32',
    img1: "../../Wedding/Souvenir/31.png",
    img2: "../../Wedding/Souvenir/32.png", 
    alt: "Vow Book",
    title: "Wedding Vow Book",
    cost: '1400',
    include: 'Two Wedding Vow Books.',
    eventType: "wedding",
    category: "souvenir & favours",
    subCategory: "Vow Book",
    desc: 'Never forget your vows.',
    url: "/wedding",
},
{
    id: '33',
    img1: "../../Wedding/Invite/82.png",
    img2: "../../Wedding/Invite/82.png",
    alt: "Benelux Program with Bow",
    title: "Benelux Fan with Bow",
    cost: '545',
    include: 'Elegant Benelux Program with bow, Ceremony Details, Guest Information, High-Quality Print',
    eventType: "wedding",
    category: "programme",
    subCategory: "Benelux Program",
    desc: 'Elevate your wedding celebration with our Benelux Elegance Wedding Program, designed to reflect the timeless charm and sophistication of the Benelux region. Perfectly crafted to guide your guests through the ceremony, this program combines functionality with a touch of elegance.',
    url: "/wedding",
},
{
    id: '34',
    img1: "../../Other/logo.png",
    img2: "../../Other/logo.png", 
    alt: "Benelux Glitter Program with Bow",
    title: "Benelux Glitter Fan with Bow",
    cost: '545',
    include: 'Elegant Benelux Program with bow, Ceremony Details, Guest Information, High-Quality Print',
    eventType: "wedding",
    category: "programme",
    subCategory: "Benelux Program",
    desc: 'Elevate your wedding celebration with our Benelux Elegance Wedding Program, designed to reflect the timeless charm and sophistication of the Benelux region. Perfectly crafted to guide your guests through the ceremony, this program combines functionality with a touch of elegance.',
    url: "/wedding",
},
{
    id: '35',
    img1: "../../Wedding/Program/64.png",
    img2: "../../Wedding/Program/64.png", 
    alt: "Fan Program with Bow",
    title: "Fan Program with Bow",
    cost: '545',
    include: 'Plain Rectangular Program with bow',
    eventType: "wedding",
    category: "programme",
    subCategory: "Fan Program",
    desc: 'Never get hot during your special event.',
    url: "/wedding",
},
{
    id: '36',
    img1: "../../Wedding/Program/39.png",
    img2: "../../Wedding/Program/62.png", 
    alt: "Metallic Rectangular Program",
    title: "Metallic Program",
    cost: '215',
    include: 'Plain Rectangular Program',
    eventType: "wedding",
    category: "programme",
    subCategory: "Flat Program",
    desc: 'Never forget your vows.',
    url: "/wedding",
},
 
    {
        id: '39',
        img1: "../../Corporate/Calendar/15.png",
        img2: "../../Corporate/Calendar/15.png",
        alt: "Calendar",
        title: "7 x 11 Calendar",
        cost: '300',
        include: 'A 7 x 11 hanging calendar with a streamlined design for easy viewing and scheduling.',
        eventType: "corporate",
        category: "calendar",
        subCategory: "Calendar",
        desc: 'Stay organized and stylish with our 7 x 11 Hanging Calendar. This compact yet versatile calendar features a sleek design that fits perfectly in any space, with ample room for marking important dates and events. Ideal for home or office use.',
        url: "/corporate",
    }, 
    {
        id: '40',
        img1: "../../Corporate/Calendar/16.png",
        img2: "../../Corporate/Calendar/22.png",
        alt: "Calendar",
        title: "11 x 17 Calendar",
        cost: '300',
        include: 'A 7 x 11 hanging calendar with a streamlined design for easy viewing and scheduling.',
        eventType: "corporate",
        category: "calendar",
        subCategory: "Calendar",
        desc: 'Stay organized and stylish with our 7 x 11 Hanging Calendar. This compact yet versatile calendar features a sleek design that fits perfectly in any space, with ample room for marking important dates and events. Ideal for home or office use.',
        url: "/corporate",
    }, 
    {
        id: '41',
        img1: "../../Corporate/Business/11.png",
        img2: "../../Corporate/Business/11.png",
        alt: "business card",
        title: "One Sided Business Card",
        cost: '2000',
        include: '100 high-quality, customizable business cards with your details and design.',
        eventType: "corporate",
        category: "business card",
        subCategory: "Business Card",
        desc: 'Make a strong impression with our Business Card. Designed for professional elegance, this card offers a sleek, customizable layout to showcase your contact information and brand identity. Perfect for networking and leaving a lasting mark.',
        url: "/corporate",
    }, 
    {
        id: '42',
        img1: "../../Corporate/Letterhead/17.png",
        img2: "../../Corporate/Letterhead/17.png",
        alt: "letter head",
        title: "Letter Head 1",
        cost: '2500',
        include: 'A custom-designed letterhead with your branding elements, printed on premium paper for a professional finish.',
        eventType: "corporate",
        category: "letter head",
        subCategory: "Letter Head",
        desc: 'Ideal for businesses, organizations, or personal use, this high-quality stationery features your logo and contact details, providing a polished and cohesive look for all your correspondence.',
        url: "/corporate",
    }, 
    {
        id: '43',
        img1: "../../Corporate/Letterhead/18.png",
        img2: "../../Corporate/Letterhead/18.png",
        alt: "letter head",
        title: "Letter Head 2",
        cost: '2500',
        include: 'A custom-designed letterhead with your branding elements, printed on premium paper for a professional finish.',
        eventType: "corporate",
        category: "letter head",
        subCategory: "Letter Head",
        desc: 'Ideal for businesses, organizations, or personal use, this high-quality stationery features your logo and contact details, providing a polished and cohesive look for all your correspondence.',
        url: "/corporate",
    }, 
    {
        id: '44',
        img1: "../../Corporate/Letterhead/19.png",
        img2: "../../Corporate/Letterhead/19.png",
        alt: "letter head",
        title: "Letter Head 3",
        cost: '2500',
        include: 'A custom-designed letterhead with your branding elements, printed on premium paper for a professional finish.',
        eventType: "corporate",
        category: "letter head",
        subCategory: "Letter Head",
        desc: 'Project professionalism with our custom Letterhead. Ideal for businesses, organizations, or personal use, this high-quality stationery features your logo and contact details, providing a polished and cohesive look for all your correspondence.',
        url: "/corporate",
    }, 
    {
        id: '49',
        img1: "../../Corporate/Shirt/2.png",
        img2: "../../Corporate/Shirt/25.png",
        alt: "custom shirt",
        title: "Shirt",
        cost: '',
        include: 'A customizable t-shirt with your choice of vinyl, heat transfer, or screen print design.',
        eventType: "corporate",
        category: "promotional items",
        subCategory: "Promotional Items",
        desc: 'Create a standout look with our Custom T-Shirt, available in vinyl, heat transfer, or screen print options. Whether you’re designing for a team, event, or personal style, choose the method that best suits your design for vibrant, long-lasting results.',
        url: "/corporate",
    },
    {
        id: '52',
        img1: "../../Corporate/Sticker/1.png",
        img2: "../../Corporate/Sticker/1.png",
        alt: "custom label",
        title: "Custom Bottle Label",
        cost: '500',
        include: 'Personalize your products or packages with our Custom Label. Whether for branding, organization, or special events, these labels offer a tailored touch to fit your needs. Choose from a range of sizes and finishes to create a unique, high-quality label.',
        eventType: "corporate",
        category: "sticker",
        subCategory: "Labels",
        desc: 'Customizable label with your design, available in various sizes and finishes for a perfect fit.',
        url: "/corporate",
    },  
    {
        id: '53',
        img1: "../../Corporate/Sticker/13.png",
        img2: "../../Corporate/Sticker/13.png",
        alt: "square stickers",
        title: "50 Square Stickers",
        cost: '1500',
        include: 'Square-shaped stickers with a high-gloss finish for a vibrant, professional appearance.',
        eventType: "corporate",
        category: "sticker",
        subCategory: "Stickers",
        desc: 'Elevate your branding or decoration with our Square Glossy Sticker. Featuring a sleek, shiny finish, these stickers bring a modern touch to your custom designs, making them perfect for logos, labels, or personal touches.',
        url: "/corporate",
    },  
    {
        id: '54',
        img1: "../../Corporate/Sticker/12.png",
        img2: "../../Corporate/Sticker/15.png",
        alt: "Circle Glossy stickers",
        title: "Circle Glossy Stickers",
        cost: '0',
        include: 'Glossy circle stickers in your custom design, delivering a sleek and eye-catching look.',
        eventType: "corporate",
        category: "sticker",
        subCategory: "Stickers",
        desc: 'Add a pop of shine to any surface with our Circle Glossy Stickers. These vibrant, high-quality stickers feature a glossy finish that makes your designs stand out. Perfect for branding, decoration, or personalizing items.',
        url: "/corporate",
    },  
    {
        id: '55',
        img1: "../../Wedding/Envelope/11.png",
        img2: "../../Wedding/Envelope/10.png",
        alt: "Metallic Envelope",
        title: "Metallic Envelope",
        cost: '110',
        include: 'Envelope',
        eventType: "wedding",
        category: "envelope",
        subCategory: "Envelope",
        desc: 'Metallic 5x7 Envelope',
        url: "/wedding",
    },  
    {
        id: '56',
        img1: "../../Corporate/Banner/5.png",
        img2: "../../Corporate/Banner/5.png",
        alt: "Banner",
        title: "Vinyl Banner - 4ft x 5ft",
        cost: '15200',
        include: 'Vinyl Banner',
        eventType: "corporate",
        category: "banner",
        subCategory: "Banner",
        desc: 'Perfect for displaying your upcoming event.',
        url: "/corporate",
    }, 
    {
        id: '57',
        img1: "../../Corporate/Banner/36.png",
        img2: "../../Corporate/Banner/36.png",
        alt: "Banner",
        title: "Retractable Banner",
        cost: '25200',
        include: 'Retractable Banner - 4ft x 5ft',
        eventType: "corporate",
        category: "banner",
        subCategory: "Banner",
        desc: 'Perfect for displaying your upcoming event.',
        url: "/corporate",
    }, 
   
    {
        id: '58',
        img1: "../../Wedding/Souvenir/35.png",
        img2: "../../Wedding/Souvenir/34.png",
        alt: "souvenir & favours for dad",
        title: "Custom mugs for dad",
        cost: '3500',
        include: 'A high-quality mug customized with your choice of text or design, tailored to make Dad’s day extra special.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Custom Mugs",
        desc: 'Celebrate Dad with our Custom Mugs, designed to make his mornings special. Personalize with a unique message, photo, or design to create a thoughtful and memorable gift. Perfect for Father’s Day, birthdays, or any occasion that honors the special man in your life.',
        url: "/wedding",
    }, 
    {
        id: '59',
        img1: "../../Wedding/Souvenir/6.png",
        img2: "../../Wedding/Souvenir/34.png",
        alt: "souvenir & favours",
        title: "Jamaican mugs",
        cost: '3500',
        include: 'A high-quality mug featuring your custom Jamaican-themed design, crafted for durability and style.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Custom Mugs",
        desc: 'Show off your Jamaican pride with our Custom Jamaican Mugs. Personalize with vibrant designs, text, or photos that celebrate Jamaica rich culture and beauty. Perfect for gifting or adding a splash of island spirit to your daily routine.',
        url: "/wedding",
    },  {
        id: '60',
        img1: "../../Wedding/Souvenir/7.png",
        img2: "../../Wedding/Souvenir/34.png",
        alt: "souvenir & favours",
        title: "Custom mugs for mom",
        cost: '3500',
        include: 'A high-quality mug customized with your choice of text or design, made to celebrate and honor Mom.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Custom Mugs",
        desc: 'Personalize with a heartfelt message, photo, or design to create a special gift she will cherish with every sip. Perfect for Mothers Day, birthdays, or just because.',
        url: "/wedding",
    }, 
   
    {
        id: '63',
        img1: "../../Corporate/Tag/28.png",
        img2: "../../Corporate/Tag/29.png",
        alt: "custom Tag",
        title: "Tag",
        cost: '0',
        include: 'A customizable clothing tag with your design, logo, and details, crafted from durable materials.',
        eventType: "corporate",
        category: "promotional items",
        subCategory: "Promotional Items",
        desc: 'Enhance your brand identity with our Custom Clothing Tag. Perfect for adding a personalized touch to your apparel, this tag features high-quality materials and customizable designs to reflect your unique style. Ideal for clothing lines, boutiques, or special collections.',
        url: "/corporate",
    }, 
    {
        id: '65',
        img1: "../../Wedding/Souvenir/4.png",
        img2: "../../Wedding/Souvenir/4.png", 
        alt: "Bottle Opener Keychain",
        title: "Bottle Opener Keychain",
        cost: '650',
        include: 'An acrylic keychain with an integrated bottle opener.',
        eventType: "wedding",
        category: "souvenir & favours",
        subCategory: "Key Chain",
        desc: 'Combine convenience with style using our Bottle Opener Acrylic Keychain. This sleek, durable keychain features a built-in bottle opener, perfect for on-the-go refreshment.',
        url: "/wedding",
    },
    {
        id: '66',
        img1: "../../Wedding/Shirt/27.png",
        img2: "../../Wedding/Shirt/31.png",  
        alt: "shirt",
        title: "Jamaican Shirt",
        cost: '6999',
        include: 'Two Shirts',
        eventType: "wedding",
        category: "shirt",
        subCategory: "Vinyl",
        desc: 'Long lasting print',
        url: "/wedding",
    }, 
    {
        id: '67',
        img1: "../../Corporate/ThankYouCard/4.png",
        img2: "../../Corporate/ThankYouCard/4.png",  
        alt: "shirt",
        title: "Thank You Card",
        cost: '3500',
        include: '100 Thank You Card',
        eventType: "corporate",
        category: "promotional cards",
        subCategory: "Promotional Cards",
        desc: 'Long lasting print',
        url: "/corporate",
    }, 
    {
        id: '68',
        img1: "../../OtherServices/TShirtBrand/8.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Limited Edition",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
    }, 
    {
        id: '69',
        img1: "../../OtherServices/TShirtBrand/21.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "100 Percent Jamaican",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
    }, 
    {
        id: '70',
        img1: "../../OtherServices/TShirtBrand/5.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Be Yourself",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
    }, 
    {
        id: '71',
        img1: "../../OtherServices/TShirtBrand/22.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Be Yourself",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
    }, 
    {
        id: '72',
        img1: "../../OtherServices/TShirtBrand/7.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Live Love Inspire",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
    },   {
        id: '73',
        img1: "../../OtherServices/TShirtBrand/9.jpg",
        img2: "../../OtherServices/TShirtBrand/4.png",  
        alt: "shirt",
        title: "Jamaican Time",
        cost: '3000',
        include: '100% cotton Shirt',
        eventType: "otherServices",
        category: "confident-fit",
        subCategory: "Confident Fit",
        desc: 'Long lasting print 100% cotton shirt.',
        url: "/other-services",
    }, 
    {
        id: '74',
        img1: "../../OtherServices/GraphicDesignClass/9.png",
        img2: "../../OtherServices/GraphicDesignClass/9.png",  
        alt: "training",
        title: "Adobe Illustrator",
        cost: '0',
        include: '4 Weeks (3 hours per session)',
        eventType: "otherServices",
        category: "training",
        subCategory: "Graphic Design",
        desc: 'This plan covers the fundamentals of Adobe Illustrator, design principles, advanced features, and practical applications.',
        url: "/other-services",
    }, 
    {
        id: '76',
        img1: "../../OtherServices/GraphicDesignClass/8.png",
        img2: "../../OtherServices/GraphicDesignClass/8.png",  
        alt: "training",
        title: "Canva",
        cost: '0',
        include: '6 Weeks (3 hours per session)',
        eventType: "otherServices",
        category: "training",
        subCategory: "Graphic Design",
        desc: 'This plan covers the fundamentals of Canva, design principles, advanced features, and practical applications.',
        url: "/other-services",
    }, 
    {
        id: '75',
        img1: "../../OtherServices/GraphicDesignClass/7.png",
        img2: "../../OtherServices/GraphicDesignClass/7.png",  
        alt: "training",
        title: "Adobe Photoshop",
        cost: '0',
        include: '6 Weeks (3 hours per session)',
        eventType: "otherServices",
        category: "training",
        subCategory: "Graphic Design",
        desc: 'This plan covers the fundamentals of Photoshop, design principles, advanced features, and practical applications.',
        url: "/other-services",
    }, 
  
    {
        id: '77',
        img1: "../../Corporate/Menu/20.png",  
        img2: "../../Corporate/Menu/20.png",  
        alt: "11x17 Menu Card",
        title: "11x17 Menu Card",
        cost: '500',
        include: "Durable 11x17 inches laminated finish, Customizable Design, Content of your choice.",
        eventType: "corporate",
        category: "menu",
        subCategory: "Menu Styles",
        desc: "Transform your restaurant’s dining experience with our elegantly designed 11x17 laminated menu card. This high-quality menu card features a sleek single-page layout, perfect for showcasing a variety of dishes and beverages. The durable lamination ensures longevity and resistance to spills and stains, while the customizable design allows for a polished and professional presentation. Ideal for enhancing your restaurant’s ambiance and providing clear, stylish information to your customers.",
        url: "/corporate",
    }, 
    {
        id: '78',
        img1: "../../Corporate/Menu/20.png",  
        img2: "../../Corporate/Menu/20.png",  
        alt: "Menu Booklet",
        title: "8.5x11 Menu Booklet",
        cost: '2499',
        include: "Custom 8.5x11 design, Durable laminated finish, Multi-page layout, High-resolution printing, Customizable sections, Detailed dish descriptions, Contact information.",
        eventType: "corporate",
        category: "menu",
        subCategory: "Menu Styles",
        desc: "Transform your restaurant’s dining experience with our elegantly designed 11x17 laminated menu card. This high-quality menu card features a sleek single-page layout, perfect for showcasing a variety of dishes and beverages. The durable lamination ensures longevity and resistance to spills and stains, while the customizable design allows for a polished and professional presentation. Ideal for enhancing your restaurant’s ambiance and providing clear, stylish information to your customers.",
        url: "/corporate",
    }, 
    {
        id: '79',
        img1: "../../Other/logo.png",  
        img2: "../../Other/logo.png",  
        img3: "../../Corporate/Menu/20.png",  
        alt: "Simple",
        title: "Simple Logo",
        cost: '5499',
        include: "One text-based logo design, High-resolution logo files in PNG and JPEG formats, Logo provided in three formats: round background, square background, and transparent background, One round of revision.",
        eventType: "corporate",
        category: "logo",
        subCategory: "Logo Packages",
        desc: "Get your brand off the ground with our Simple Logo Package. This package offers a high-quality, text-based logo designed to establish your brand identity with clarity and style. You will receive your logo in three versatile formats",
        url: "/corporate",
    }, 
    {
        id: '80',
        img1: "../../Other/logo.png",  
        img2: "../../Other/logo.png",  
        alt: "Complex Logo",
        title: "Complex Logo",
        cost: '7499',
        include: "One custom logo design incorporating text and imagery, High-resolution logo files in PNG, JPEG, Logo provided in multiple formats: round background, square background, and transparent background, Two rounds of revisions.",
        eventType: "corporate",
        category: "logo",
        subCategory: "Logo Packages",
        desc: "Elevate your brand with our Complex Logo Package. This comprehensive package delivers a custom-designed logo that integrates both text and imagery, creating a distinctive and impactful brand identity. With two rounds of revisions, you'll have the opportunity to refine the design to perfection. You'll also receive your logo in multiple high-resolution formats and vector files for ultimate versatility.",
        url: "/corporate",
    }, 
    {
        id: '81',
        img1: "../../Corporate/Business/11.png",
        img2: "../../Corporate/Business/11.png",
        alt: "business card",
        title: "Two Sided Business Card",
        cost: '4000',
        include: '100 high-quality, customizable business cards with your details and design.',
        eventType: "corporate",
        category: "business card",
        subCategory: "Business Card",
        desc: 'Make a strong impression with our Business Card. Designed for professional elegance, this card offers a sleek, customizable layout to showcase your contact information and brand identity. Perfect for networking and leaving a lasting mark.',
        url: "/corporate",
    }, 
    {
        id: '82',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",  
        alt: "Loyalty Card",
        title: "100 Loyalty Card",
        cost: '2000',
        include: '100 high-quality Loyalty Card, customizable cards with your details and design',
        eventType: "corporate",
        category: "promotional cards",
        subCategory: "Promotional Cards",
        desc: 'High-quality Loyalty Card perfect for customer retenetion.',
        url: "/corporate",
    }, 
    {
        id: '83',
        img1: "../../Other/logo.png",
        img2: "../../Other/logo.png",  
        alt: "Discount Card",
        title: "Discount Card",
        cost: '2000',
        include: '100 high-quality Discount Card, customizable cards with your details and design',
        eventType: "corporate",
        category: "promotional cards",
        subCategory: "Promotional Cards",
        desc: 'High-quality Discount Card perfect for customer retenetion.',
        url: "/corporate",
    }, 
    {
        id: '84',
        img1: "../../Wedding/Program/61.png",
        img2: "../../Wedding/Program/61.png", 
        alt: "Plain Rectangular Program",
        title: "Plain Program",
        cost: '205',
        include: ' Wedding Vow BooksTwo.',
        eventType: "wedding",
        category: "programme",
        subCategory: "Flat Program",
        desc: 'Never forget your vows.',
        url: "/wedding",
    },
    {
        id: '85',
        img1: "../../Wedding/Program/36.png",
        img2: "../../Wedding/Program/37.png", 
        alt: "Fan Program",
        title: "Fan Program",
        cost: '515',
        include: 'Plain Rectangular Program',
        eventType: "wedding",
        category: "programme",
        subCategory: "Fan Program",
        desc: 'Never get hot during your special event.',
        url: "/wedding",
    },
    {
        id: '86',
        img1: "../../Wedding/PlaceCard/TableNumber.png",
        img2: "../../Wedding/PlaceCard/TableNumber2.png", 
        alt: "Table Number",
        title: "Table Number",
        cost: '250',
        include: 'Table Number Card',
        eventType: "wedding",
        category: "table card",
        subCategory: "4x6 Table Number",
        desc: 'Sleek elegant table number crafted from C1s Cardstock. Dimensions: W4" x L6" ',
        url: "/wedding",
    },
    {
        id: '87',
        img1: "../../Wedding/Invite/81.png",
        img2: "../../Wedding/Invite/81.png",
        alt: "Velvet Invitation",
        title: "Velvet Invitation With Accent",
        cost: '1355',
        include: 'Gold Foil Detailing, Velvet Cover, Gold String Wrap, Wax Seal Emblem, Baby’s Breath Accent, Personalized Invitation Text, Event Details (Date, Time, Venue), RSVP Information, Optional Additional Inserts (Directions, Accommodation)',
        eventType: "wedding",
        category: "invitation",
        subCategory: "Velvet Invitation",
        desc: 'Experience the opulence of our Luxurious Gold Foil Wedding Invitation—a true masterpiece of elegance and refinement. Encased in a sumptuous velvet cover, this invitation is wrapped in a delicate gold string and sealed with a classic wax emblem, embodying sophistication. Each piece is adorned with a touch of baby’s breath, adding a natural, romantic element that perfectly complements the gold foil detailing.',
        url: "/wedding",
    }, 
  
  
   

];

export default inventoryData;