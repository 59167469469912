import React, {Fragment} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './ProductCarousel.module.css';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FeaturedCategoryCard from './FeaturedCategoryCard';
const FeaturedProductCarousel = () => {
    const featuredCategory = [
        {
            id:1,
            categoryName:'Wedding Invitations',
            img:'../Wedding/Invite/1.png',
            url:'/wedding/invitation'
        },{
            id:2,
            categoryName:'Wedding Programmes',
            img:'../Wedding/Program/36.png',
            url:'/wedding/programme'
        },{
            id:3,
            categoryName:'Wedding Shirts',
            img:'../Wedding/Shirt/11.png',
            url:'/wedding/shirt'
        }
    ]
    const featuredCorCategory = [
        {
            id:1,
            categoryName:'Banner',
            img:'../Corporate/Banner/5.png',
            url:'/corporate/banner'
        },{
            id:2,
            categoryName:'Business Cards',
            img:'../Corporate/Business/11.png',
            url:'/Corporate/programme'
        },{
            id:3,
            categoryName:'Menu',
            img:'../Corporate/Menu/20.png',
            url:'/Corporate/menu'
        }
    ]
    const CustomNextArrow = (props) => {
        return(
            <button {...props} className={styles['carousel-arrow-right']}>
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        );
    }

    const CustomPrevArrow = (props) => {
        return(
            <button {...props} className={styles['carousel-arrow-left']}>
                <span> <FontAwesomeIcon icon={faChevronLeft} /></span>
            </button>
        );
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of cards to show at once
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024, // Adjust breakpoints as needed
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Fragment>
            <div className={styles['product-carousel-container']}>
                <Slider {...settings}>
                    {featuredCategory.map((event) => (
                        <div key={event.id} className={styles['product-card-container']}>
                            <FeaturedCategoryCard category={event} />
                        </div>
                    ))}
                </Slider>
            </div>
            <div className={styles['product-carousel-container']}>
                <Slider {...settings}>
                    {featuredCorCategory.map((event) => (
                        <div key={event.id} className={styles['product-card-container']}>
                            <FeaturedCategoryCard category={event} />
                        </div>
                    ))}
                </Slider>
            </div>
        </Fragment>
    );
};

export default FeaturedProductCarousel;
