import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import ReviewsCards from '../../components/ReviewsCards';
import { Helmet } from 'react-helmet';

const Reviews = () => {

    return (
        <div>
            <Helmet>
            <title>Reviews - Personalized Cards and Invitations</title>
            <meta name="description" content="Read reviews from our satisfied customers at Personalized Cards and Invitations. Discover feedback on our custom cards and invitations, and see how our products and services have delighted our clients." />
  </Helmet>
            <HeaderSection title='Customer Reviews'/>
            <ReviewsCards/>
        </div>

    );
};

export default Reviews;