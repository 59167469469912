import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import WhoAreWe from './WhoAreWe';
import Mission from './Mission';
import WhatSetsUsApart from './WhatSetsUsApart';
import RangeOfProducts from './RangeOfProducts';
import ConnectWithUs from './ConnectWithUs';
import ShipIslandwide from './ShipIslandwide';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    return (
        <div>
            <Helmet>
            <title>About Us - Personalized Cards and Invitations</title>
            <meta name="description" content="Learn more about Personalized Cards and Invitations, our mission, and the team behind the designs." />
            </Helmet>
            <HeaderSection title='About Us'/>
            <WhoAreWe/>
            <Mission/>
            <WhatSetsUsApart/>
            <RangeOfProducts/>
            <ShipIslandwide/>
            <ConnectWithUs/>
        </div>
    );
};

export default AboutUs;