import React from 'react';
import styles from './CardBtn.module.css';



const CardBtn = ({title}) => {

    return (
        <div>

                <button className={styles['btn']}>{title}</button>

        </div>
    );
};

export default CardBtn;