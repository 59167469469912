import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useEffect} from 'react';
import Nav from './components/kevnavbar';
import Landing from './pages/landing/Landing';
import Wedding from './pages/wedding/Wedding';
import OtherServices from './pages/otherServices/OtherServices';
import OtherServicesCatalogue from './pages/otherServices/OtherServicesCatalogue';
import Footer from './components/Footer';
import Product from './components/Product';
import './index.css';
import Catalogue from './pages/wedding/Catalogue'
import Corporate from './pages/corporate/Corporate';
import CorporateCatalogue from './pages/corporate/CorporateCatalogue';
import AboutUs from './pages/about-us/AboutUs';
import Reviews from './pages/reviews/Reviews';
import TermsAndConditions from './pages/policies/TermsAndConditions';
import FAQ from './pages/faq/FAQ';
import ShippingPolicy from './pages/policies/ShippingPolicy';
import RefundPolicy from './pages/policies/RefundPolicy';
import Banner from './components/Banner/Banner';
import FormList from './pages/formList/FormList';
import ScrollToTop from './components/Scroll/ScrollToTop';
import Blog from './pages/blog/Blog';

function App() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <BrowserRouter>
<ScrollToTop />
            <Nav/>
                <Banner/>
                <Routes>
                    <Route path='/' element={<Landing/>}/>
                    <Route path='/about-us' element={<AboutUs/>}/>
                    <Route path='/faqs' element={<FAQ/>}/>
                    <Route path='/neikas-wedding-corner' element={<Blog/>}/>
                    <Route path='/forms' element={<FormList/>}/>
                    <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
                    <Route path='/shipping-policy' element={<ShippingPolicy/>}/>
                    <Route path='/refund-policy' element={<RefundPolicy/>}/>
                    <Route path='/reviews' element={<Reviews/>}/>
                    <Route path='/corporate' element={<Corporate/>}/>
                    <Route path='/corporate/:productCategory' element={<CorporateCatalogue/>}/>
                    <Route path='/corporate/:productId/:productTitle' element={<Product/>}/>
                    <Route path='/wedding' element={<Wedding/>}/>
                    <Route path='/wedding/:productCategory' element={<Catalogue/>}/>
                    <Route path='/wedding/:productId/:productTitle' element={<Product/>}/>
                    <Route path='/other-services' element={<OtherServices/>}/>
                    <Route path='/other-services/:productCategory' element={<OtherServicesCatalogue/>}/>
                    <Route path='/other-services/:productId/:productTitle' element={<Product/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>
    );
}

export default App;
