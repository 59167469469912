import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import styles from './FAQ.module.css';
import { Helmet } from 'react-helmet';

const FAQ = () => {
    return (
        <div>
            <Helmet>
            <title> Frequently Asked Questions (FAQ) - Personalized Cards and Invitations</title>
            <meta name="description" content="Find answers to common questions about our personalized cards and invitations. Learn more about our services, ordering process, customization options, and more." />
</Helmet>
            <HeaderSection title='Frequently Asked Questions'/>
            <div className={styles.faqContainer}>
                <section className={styles.section}>
                    <h2 className={styles.faqHeader}>1. What types of wedding stationery do you offer?</h2>
                    <p>
                    We offer a wide range of wedding stationery including save-the-date cards, invitations, RSVP cards, wedding programs, place cards, thank-you cards, and more. We also provide custom designs to match your unique theme and style.   </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.faqHeader}>2. Can I customize my wedding invitations?</h2>
                    <p>
                    Absolutely. We offer full customization options for your wedding invitations. You can select from various designs, colors, fonts, and paper types, or provide your own artwork for a completely bespoke design.
      </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.faqHeader}>3. What is the process for ordering custom wedding stationery?</h2>
                    <p>
                    To proceed with an order through PC&I:  </p>
                    <ol>
                        <li><strong>Select and submit your order.</strong></li>
                        <li><strong>Receive quotation</strong></li>
                        <ul>
                            <li>PC&I representative will get in touch with you and provide invoice for payment.</li>
                        </ul>
                        <li><strong>Make Payment Deposit.</strong></li>
                        <ul>
                            <li>You have the option to pay in full or make 50% deposit of the total amount to initiate the design process.</li>
                        </ul>
                        <li><strong>Design Draft.</strong></li>
                        <ul>
                            <li>Our team will work on creating a design draft based on your requirements within  3-7 business days.</li>
                            <li>You will have the opportunity to review and confirm the design.</li>
                        </ul>
                        <li><strong>Balance Payment.</strong></li>
                        <ul>
                            <li>Upon confirmation of the design draft, the remaining 50% of the total amount is to be paid to confirm production.</li>
                        </ul>
                        <li><strong>Production and Delivery.</strong></li>
                        <ul>
                            <li>Kindly allow a timeframe of 3 to 6 weeks for production completion. However, based on the quantity
ordered you may receive it sooner.
Following production, delivery will be facilitated through Bearer Services, Knutsford Courier
Services, or Tara Courier Service.
</li>
                        </ul>
                    </ol>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.faqHeader}>4. Shipping and Delivery</h2>
                    <p>
                    For your convenience, we extend islandwide shipping services. Delivery timelines may vary based
on your location and the specific product selected. Estimated delivery schedules will be provided
during the ordering process. While we endeavor to adhere to these estimates, please understand
that delays may occasionally arise due to unforeseen circumstances. Your patience and
understanding in such instances are greatly appreciated. Delivery options include Bearer Services,
Knutsford Courier Services, or Tara Courier Service, ensuring reliable and efficient delivery choices.   </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.faqHeader}>5. What are the payment options?</h2>
                    <p>
                    We accept bank transfers. A deposit is required to begin work on custom designs, with the remaining balance due before delivery. </p>
                </section>

               
                
            </div>

        </div>
    );
};

export default FAQ;