import React from 'react';
import styles from './ReviewsCards.module.css';
import google from '../assets/images/google.png';
import whatsapp from '../assets/images/google.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';

const ReviewsCards = () => {
    const reviewsData = [
        {
            name: 'S. Smellie',
            // date: '9 months ago',
            reviewStarCount: 5,
            reviewMessage: 'Did a last minute order and I got it within like 2 days after contacting them. If you’re running late or have a mishap or anything trust me look no further PC&I is the final stop. Thank you again',
            socialMedia: 'Google',
        }, {
            name: 'L. Edwards',
            // date: '9 months ago',
            reviewStarCount: 5,
            reviewMessage: 'Design is top tier...great customer service definitely recommend...',
            socialMedia: 'Google',
        },
        {
            name: 'Instituteja',
            // date: 'a year ago',
            reviewStarCount: 5,
            reviewMessage: 'Great customer service! Owner ensured I got my invitation on time even though it was a short notice.. Appreciate it :) Def would recommend',
            socialMedia: 'Google',
        },

        {
            name: 'A. Student',
            // date: 'a year ago',
            reviewStarCount: 5,
            reviewMessage: 'AWESOME on time designs! Great quality...definitely will shop again!!',
            socialMedia: 'Google',
        },
        {
            name: 'Triesha Beckford',
            // date: '5 days ago',
            reviewStarCount: 5,
            reviewMessage: '',
            socialMedia: 'Google',
        }, 
        {
            name: 'Horseback Ocean Riding',
            // date: '5 days ago',
            reviewStarCount: 5,
            reviewMessage: '',
            socialMedia: 'Google',
        }, 
        { 
            name: 'Paula Virgo',
            // date: '5 days ago',
            reviewStarCount: 5,
            reviewMessage: 'Customer Service is top tier not to mention the quality of the products ♥',
            socialMedia: 'Google',
        }
    ];

    return (
        <div>
            <div className={styles.container}>
                <h2>What our clients say</h2>
                
                <div className={styles['review-section']}>
                    {reviewsData.map((data) => {
                        return (
                            <div className={styles['review-item']}>
                                <div className={styles.top}>
                                    <div className={styles['client-image']}>
                                        {
                                            data.socialMedia === 'Google' ?
                                                <img src={google} alt="google logo"  loading="lazy"/>
                                                :
                                                <img src={whatsapp} alt="whatsapp logo"  loading="lazy"/>

                                        }
                                        <span className={styles.reviewer}>{data.name}</span>
                                        <ul>
                                            {Array.from({length: data.reviewStarCount}).map((_, index) => (
                                                <li key={index}>
                                                    <FontAwesomeIcon icon={faStar}/>
                                                </li>))}
                                        </ul>
                                    </div>

                                </div>
                                <article>
                                    <p className="review">
                                        {data.reviewMessage}
                                    </p>
                                    <p>{data.date}</p>
                                </article>
                            </div>
                        )

                    })}
                </div>

            </div>
        </div>


    );
};

export default ReviewsCards;
