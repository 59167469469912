const otherServicesProductCategory = [
  
    {
        id: 1,
        src: "../OtherServices/GraphicDesignClass/training.png",
        alt: "training",
        title: "training",
        url: "/other-services/training",
    },
    {
        id: 2,
        src: "../OtherServices/TShirtBrand/8.jpg",
        alt: "tshirt brand",
        title: "confident-fit",
        url: "/other-services/confident-fit",
    },
    
    
];

export default otherServicesProductCategory;